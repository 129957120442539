export default function Warnings() {
    return (
        <div className="WarningPage">
            <div className="warningpage-bodyContainer">
                <div className="warningpage-textContainer">
                    <div className="warningpage-text">The author is an entity that holds the knowledge and power to define the structure of the message he wants to protray.</div>
                    {/* <div className="warningpage-text">The web interface has been well structured in a way that you should not be required to type in any URL manually to gain access to other parts of the site.</div> */}
                    <div className="warningpage-textBold">The site you are trying to access do not exist.</div>
                </div>
            </div>
        </div>
    )
}